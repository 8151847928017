import { ToolEnum } from '~/constants/drawing'
import type { SrcType } from '~/types/type'

const base64ToBlob = (base64Data: string) => {
  const base64WithoutHeader = base64Data.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
  const byteCharacters = atob(base64WithoutHeader)
  const byteNumbers = new Uint8Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  return new Blob([byteNumbers], { type: 'image/png' })
}

export const useCanvasStore = defineStore('canvas', () => {
  const targetImages = ref<{ before: SrcType, after: SrcType }>({ before: null, after: null })
  const targetImage = ref<SrcType>(null)
  const selectedTool = ref(ToolEnum.Brush)
  const strokeWidth = ref(30)
  const strokeColor = ref('#ffffff')
  const strokeOpacity = ref(0.7)
  const maskUrl = ref<SrcType>(null)
  const previewMaskUrl = ref<SrcType>(null)
  const maskMetadata = ref<{
    width: number
    height: number
    scale: number
  } | null>(null)

  const setTargetImages = (images: { before: SrcType, after: SrcType }) => {
    targetImages.value = images
  }

  const setTargetImage = (image: SrcType) => {
    targetImage.value = image
  }

  const initTargetImage = () => {
    targetImage.value = null
    targetImages.value = { before: null, after: null }
  }

  const setSelectedTool = (newSelectedTool: ToolEnum) => {
    selectedTool.value = newSelectedTool
  }

  const setMaskImage = (base64Data: string, previewBase64Data: string, metadata: typeof maskMetadata.value) => {
    if (maskUrl.value) {
      URL.revokeObjectURL(maskUrl.value)
    }
    if (previewMaskUrl.value) {
      URL.revokeObjectURL(previewMaskUrl.value)
    }

    const maskBlob = base64ToBlob(base64Data)
    const previewBlob = base64ToBlob(previewBase64Data)

    maskUrl.value = URL.createObjectURL(maskBlob)
    previewMaskUrl.value = URL.createObjectURL(previewBlob)

    maskMetadata.value = metadata
  }

  const clearMask = () => {
    if (maskUrl.value) {
      URL.revokeObjectURL(maskUrl.value)
      maskUrl.value = null
    }
    if (previewMaskUrl.value) {
      URL.revokeObjectURL(previewMaskUrl.value)
      previewMaskUrl.value = null
    }
    maskMetadata.value = null
  }

  return {
    targetImages,
    setTargetImages,
    targetImage,
    setTargetImage,
    initTargetImage,
    selectedTool,
    strokeWidth,
    strokeColor,
    strokeOpacity,
    setSelectedTool,
    maskUrl,
    previewMaskUrl,
    maskMetadata,
    setMaskImage,
    clearMask,
  }
})
